import React from 'react';
import { string } from 'prop-types';
import { Box, Text, AspectRatio, Button, Center, Link } from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import { THE_BRIDE, YOUTUBE_EMBED, YOUTUBE_LINK } from '@/constants';
import txt from './locales';

function YoutubeLiveSection({ lang, ...rest }) {
  return (
    <Box bgColor="bgPrimary" padding="42px 24px" {...rest}>
      <Box textAlign="center">
        <WithAnimation>
          <Text color="mainColorText" fontSize="3xl">
            LIVE WEDDING
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Text fontSize="md" color="mainColorText" marginTop="12px">
            {txt.subtitle[lang]}
          </Text>
        </WithAnimation>
      </Box>
      <Box padding="32px 0">
        <WithAnimation>
          <AspectRatio maxW="560px" ratio={16 / 9} borderRadius="lg">
            <iframe
              title={`Live wedding of ${THE_BRIDE}`}
              src={YOUTUBE_EMBED}
              allowFullScreen
              loading="lazy"
              style={{ borderRadius: '16px' }}
            />
          </AspectRatio>
        </WithAnimation>
      </Box>
      <Center>
        <Link href={YOUTUBE_LINK} target="_blank">
          <Button
            fontSize="sm"
            size="sm"
            fontWeight="light"
            bgColor="bgSecondary"
            textColor="secondaryColorText"
          >
            {txt.openYoutube[lang]}
          </Button>
        </Link>
      </Center>
    </Box>
  );
}

YoutubeLiveSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(YoutubeLiveSection);
