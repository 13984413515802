import get from '@invitato/helpers/dist/getLocale';

export default {
  dear: get('Kepada Bapak/Ibu/Saudara/i,', 'Dear'),
  friend: get('Keluarga & Teman-teman', 'Family & Friends'),
  announcement: get(
    `Sebuah kehormatan bagi kami dapat mengumumkan kabar bahagia pada hari ini. Tanpa mengurangi rasa hormat, mengingat masih dalam masa pandemi, kami mohon maaf tidak bisa mengundang Bapak/Ibu/Saudara/i secara langsung dalam acara pernikahan kami. Tetapi kami tetap memohon doa restu untuk keberlangsungan acara pernikahan kami.`,
    `We are pleased to announce our upcoming wedding to the family and friends.`,
  ),
  ayatName: get('[Matius 19:6]', '[Matthew 19:6]'),
  ayatNameMuslim: get('[Q.S. Ar-Rum: 21]', '[Q.S. Ar-Rum: 21]'),
  ayat: get(
    `“Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia.”`,
    `“So they are no longer two, but one flesh. Therefore what God has joined together, let no one separate.”`,
  ),
  ayatMuslim: get(
    `“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan diantaramu rasa kasih dan sayang. Sungguh, pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”`,
    `“And it is among His signs that He has created for you wives from among yourselves, so that you may find tranquility in them, and He has created love and kindness between you. Surely in this there are signs for a people who reflect.”`,
  ),
};
