import React from 'react';
import { string, func } from 'prop-types';
import { Box, Text, Link, Image, Center } from '@chakra-ui/react';

import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';
import WithAnimation from '@/components/Common/WithAnimation';
import txtWording from './locales';

function DetailCard({ fullName, imgUrl, instagramId, description, onClickReadMore }) {
  return (
    <Box color="mainColorText">
      <WithAnimation>
        <Image
          src={imgUrl}
          alt=""
          width="100%"
          maxHeight="350px"
          objectFit="cover"
          objectPosition="top"
        />
      </WithAnimation>
      <WithAnimation>
        <Text fontFamily="heading" fontSize="2xl" marginTop="16px" textAlign="center" fontWeight="600"
          dangerouslySetInnerHTML={{__html: fullName.replace('S.E., M.M., RFP', '<br/>S.E., M.M., RFP')}}
        >
        </Text>
      </WithAnimation>
      {instagramId && (
        <WithAnimation>
          <Center>
            <Link
              href={`https://instagram.com/${instagramId}`}
              target="_blank"
              textAlign="center"
              _hover={{textDecoration: "none"}}
            >
              {'@'}
              <span style={{textDecoration: "underline"}}>{instagramId}</span>
            </Link>
          </Center>
        </WithAnimation>
      )}
      <WithAnimation>
          <Text marginTop="16px" textAlign="center" dangerouslySetInnerHTML={{ __html: description }} />
      </WithAnimation>
      {false && typeof onClickReadMore !== 'undefined' && (
        <Text
          marginTop="8px"
          textAlign="end"
          fontStyle="italic"
          textDecoration="underline"
          cursor="pointer"
          onClick={onClickReadMore}
        >
          {txtWording.readMore[lang]}
        </Text>
      )}
    </Box>
  );
}

DetailCard.propTypes = {
  fullName: string.isRequired,
  imgUrl: string.isRequired,
  instagramId: string,
  description: string,
  onClickReadMore: func,
};

DetailCard.defaultProps = {
  instagramId: '',
  description: '',
  onClickReadMore: () => {},
};

export default React.memo(DetailCard);
