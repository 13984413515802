import React, { useEffect } from 'react';
import { Tabs, TabList, Tab, TabPanels, TabPanel, useDisclosure, Center } from '@chakra-ui/react';

import DetailCard from './DetailCard';
import LoveStory from './LoveStory';

import {
  GIRL_NAME_SHORT,
  BOY_NAME_SHORT,
  GIRL_NAME,
  BOY_NAME,
  IG_GIRL,
  IG_BOY,
  GIRL_PARENT_NAME,
  BOY_PARENT_NAME,
  IS_BOY_FIRST,
} from '@/constants';

import { IMG_MAN, IMG_GIRL, IMG_LOVE_STORY } from '@/constants/assets';
import { ENABLE_LOVE_STORY, DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';

import txtWording from './locales';
import useCobaLink from '@/hooks/useCobaLink';
import useRsvp from '@/usecase/use-rsvp';

const TAB_PROPS = {
  textTransform: 'uppercase',
  letterSpacing: '2px',
  fontSize: 'sm',
  color: 'mainColorText',
  fontWeight:'bold',
  _selected: { color: 'mainColorText', borderBottom: '2px solid #c0796c' },
  _visited: { color: 'mainColorText', borderBottom: '2px solid #c0796c' },
};

const loveStory = `
  Anin dan Ariq memiliki dua karakter yang berbeda. Jika Anin cenderung lebih percaya diri, Ariq adalah sosok yang mulanya sangat pemalu dan introvert.
  <br /> <br />
  Mereka berdua adalah dua sejoli yang dipertemukan oleh Maha Kuasa di SMP 9 Semarang. Sejak awal melihat Anin, Ariq sangat kagum dengan sosok Anin yang....
`;

function CoupleInfo({ ...rest }) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { onOpenInvitation } = useRsvp();
  const { onValidateCobaLink } = useCobaLink();

  useEffect(() => {
    onValidateCobaLink(onOpenInvitation);
  }, []);

  return (
    <Center bgColor="bgPrimary" padding="0 32px 16px 32px" {...rest}>
      <Tabs variant="unstyled">
        {IS_BOY_FIRST && (
          <TabList justifyContent="center">
            <Tab {...TAB_PROPS}>{BOY_NAME_SHORT}</Tab>
            <Tab {...TAB_PROPS}>{GIRL_NAME_SHORT}</Tab>
            {ENABLE_LOVE_STORY && <Tab {...TAB_PROPS}>How We Met</Tab>}
          </TabList>
        )}
        {!IS_BOY_FIRST && (
          <TabList>
            <Tab {...TAB_PROPS}>{GIRL_NAME_SHORT}</Tab>
            <Tab {...TAB_PROPS}>{BOY_NAME_SHORT}</Tab>
            {ENABLE_LOVE_STORY && <Tab {...TAB_PROPS}>How We Met</Tab>}
          </TabList>
        )}
        {IS_BOY_FIRST && (
          <TabPanels padding="16px 0">
            <TabPanel padding="0">
              <DetailCard
                fullName={BOY_NAME}
                imgUrl={IMG_MAN}
                instagramId={IG_BOY}
                description={BOY_PARENT_NAME}
              />
            </TabPanel>
            <TabPanel padding="0">
              <DetailCard
                fullName={GIRL_NAME}
                imgUrl={IMG_GIRL}
                instagramId={IG_GIRL}
                description={GIRL_PARENT_NAME}
              />
            </TabPanel>
            {ENABLE_LOVE_STORY && (
              <TabPanel padding="0">
                <DetailCard
                  fullName={txtWording.loveStoryName[lang]}
                  imgUrl={IMG_LOVE_STORY}
                  description={loveStory}
                  onClickReadMore={onOpen}
                />
              </TabPanel>
            )}
          </TabPanels>
        )}
        {!IS_BOY_FIRST && (
          <TabPanels padding="16px 0">
            <TabPanel padding="0">
              <DetailCard
                fullName={GIRL_NAME}
                imgUrl={IMG_GIRL}
                instagramId={IG_GIRL}
                description={GIRL_PARENT_NAME}
              />
            </TabPanel>
            <TabPanel padding="0">
              <DetailCard
                fullName={BOY_NAME}
                imgUrl={IMG_MAN}
                instagramId={IG_BOY}
                description={BOY_PARENT_NAME}
              />
            </TabPanel>
            {ENABLE_LOVE_STORY && (
              <TabPanel padding="0">
                <DetailCard
                  fullName={txtWording.loveStoryName[lang]}
                  imgUrl={IMG_LOVE_STORY}
                  description={loveStory}
                  onClickReadMore={onOpen}
                />
              </TabPanel>
            )}
          </TabPanels>
        )}
      </Tabs>
      <LoveStory onClose={onClose} visible={isOpen} />
    </Center>
  );
}

export default React.memo(CoupleInfo);
