import React, { useState, useEffect } from 'react';
import { func, bool } from 'prop-types';

import { Box, Text } from '@chakra-ui/react';
import useInvitation from '@/hooks/useInvitation';
import WithAnimation from '@/components/Common/WithAnimation';

import playMusic from '@/components/Common/FloatingMusic/utils/playMusic';
import Image from '@/components/Common/LazyImage';
import ScrollToDown from '@/components/Common/ScrollToDown';

import { THE_BRIDE, WEDDING_DATE } from '@/constants';
import { BG_PRIMARY } from '@/constants/colors';
import { BG_COVER, IMG_LOGO } from '@/constants/assets';
import { useGuest } from '@/context/guest';

/**
 * Function to render Component CoverV3
 * This component will be dissapear after user click show `Undangan`
 * @return {JSX.Element}
 */
function Cover({ loaded, onSeeDetail }) {
  const [loading, setLoading] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const { isLoading } = useGuest();
  const isInvitation = useInvitation();

  const handleLoadingComplete = () => {
    onSeeDetail();
    setLoading(false);
  };

  const handleClickDetail = () => {
    if (loading) return;

    playMusic();
    setLoading(true);
  };

  useEffect(() => {
    // side-effect to show next page after guest data ready
    // @important we use interval to make sure guest data is ready first before we go to next section
    setShowTitle(true);
    if (loading) {
      let intervalGuest = setInterval(() => {
        if (!isLoading) {
          clearInterval(intervalGuest);
          handleLoadingComplete();
        }
      }, 1500);
    }
  }, [loading, isLoading, isInvitation]);

  return (
    <Box minHeight="100vh" backgroundColor={BG_PRIMARY}>
      <Box
        height="100vh"
        width="100%"
        bgSize="cover"
        bgPosition="center"
        bgImage={`url(${BG_COVER})`}
      >
        <Box position="absolute" width="100%" maxWidth="500px">
          <Image src={IMG_LOGO} filter="invert(1)" maxWidth="40px" position="absolute" right="16px" top="24px" />
        </Box>
      </Box>
      {showTitle && (
        <Box
          bottom="4rem"
          position="absolute"
          color="secondaryColorText"
          letterSpacing="1px"
          fontWeight="400"
          fontSize="10px"
          width="100%"
          maxW="500px"
          top="calc(100vh - 250px)"
          height="120px"
        >
          <Box>
            {!isInvitation && (
              <WithAnimation>
                <Text
                  textAlign="center"
                  fontSize="lg"
                  color="white"
                  textTransform="uppercase"
                  letterSpacing="2px"
                >
                  WEDDING ANNOUNCEMENT
                </Text>
              </WithAnimation>
            )}
            <WithAnimation>
              <Text
                textAlign="center"
                fontSize="2xl"
                color="white"
                textTransform="uppercase"
                letterSpacing="2px"
              >
                {THE_BRIDE}
              </Text>
            </WithAnimation>
            <WithAnimation>
              <Text
                textAlign="center"
                fontSize="lg"
                color="white"
                textTransform="uppercase"
                letterSpacing="2px"
              >
                {WEDDING_DATE}
              </Text>
            </WithAnimation>
            <WithAnimation>
              <ScrollToDown
                loaded={loaded}
                loading={loading}
                marginTop="16px"
                onClick={handleClickDetail}
              />
            </WithAnimation>
          </Box>
        </Box>
      )}
    </Box>
  );
}

Cover.propTypes = {
  loaded: bool.isRequired,
  onSeeDetail: func.isRequired,
};

export default Cover;
