/**
 * @function normalizeWishes
 * @description normalize wishes data
 * @returns {Array<wish>}
 */
function normalizeWishes(wishes = []) {
  if (!wishes) return [];

  return wishes.map((item) => {
    return {
      name: item.NAME || '',
      wish: item.WISH || '',
      isPinByAdmin: Number(item.PIN_BY_ADMIN || 0),
      time: item.SUBMITTED_TIME || '',
    };
  });
}

export default normalizeWishes;
