import React from 'react';
import { Heading, Center, Box, Divider, Text, Button, useDisclosure } from '@chakra-ui/react';

import useInvitation from '@/hooks/useInvitation';

import WithAnimation from '@/components/Common/WithAnimation';
import ViewLocationButton from '@/components/Common/Buttons/ViewLocation';
import QRCard from '@/components/Common/QRCard/V2';
import WeddingDetail from './WeddingDetail';
import WeddingProtocol from './WeddingProtocol';
import generateShift from '@/hooks/useShift';
import { useGuest } from '@/context/guest';
import {
  WEDDING_AKAD_TIME,
  WEDDING_AKAD,
  WEDDING_LOCATION_AKAD,
  WEDDING_LOCATION_AKAD_ROAD,
  WEDDING_LOCATION,
  WEDDING_LOCATION_ROAD,
  WEDDING_RESEPSI_DRESSCODE,
} from '@/constants';
import { ID_COVID_SECTION, ID_QRCODE } from '@/constants/identifier';
import {
  ENABLE_QR_INVITATION,
  ENABLE_COVID_PROTOCOL,
  DEFAULT_LANGUAGE as lang,
  ENABLE_MUSLIM_LANGUAGE,
} from '@/constants/feature-flags';
import { RSVP_STATUS } from '@/constants/api';

import txtWording from './locales';

function WeddingSection({ ...rest }) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const isInvitation = useInvitation();
  const { guest } = useGuest();
  const { shift } = guest;
  const resepsiTime = generateShift(shift);

  return (
    <>
      <Box padding="32px 0" bgColor="bgSecondary" {...rest}>
        <WithAnimation>
          <Center>
            <Heading
              margin="24px 0"
              color="secondaryColorText"
              fontFamily="body"
              textTransform="uppercase"
              fontSize="xl"
            >
              The Wedding
            </Heading>
          </Center>
          <Center>
            <Divider
              height="1px"
              bgColor="bgPrimary"
              width="100%"
              borderBottomWidth="none"
              opacity="1"
              marginBottom="24px"
            />
          </Center>
        </WithAnimation>
        {/* Wedding info section */}
        <Box padding="0 16px">
          <WithAnimation>
            <WeddingDetail
              date={WEDDING_AKAD}
              time={WEDDING_AKAD_TIME}
              title={ENABLE_MUSLIM_LANGUAGE ? txtWording.akad[lang] : txtWording.holy[lang]}
              subTitle={
                ENABLE_MUSLIM_LANGUAGE
                  ? txtWording.subtitleAkad[lang]
                  : txtWording.subtitleHoly[lang]
              }
            />
          </WithAnimation>
          {isInvitation && (
            <WithAnimation>
              <Text
                fontSize="sm"
                textAlign="center"
                textTransform="uppercase"
                fontWeight="bold"
                color="secondaryColorText"
                dangerouslySetInnerHTML={{ __html: WEDDING_LOCATION_AKAD }}
              />
              <Text
                fontSize="xs"
                textAlign="center"
                textTransform="uppercase"
                color="secondaryColorText"
                marginBottom="24px" 
              >
                {WEDDING_LOCATION_AKAD_ROAD}
              </Text>
          </WithAnimation>)
          }
          {isInvitation && (
            <WithAnimation>
              <WeddingDetail
                date={WEDDING_AKAD}
                time={resepsiTime}
                dresscode={WEDDING_RESEPSI_DRESSCODE}
                title={txtWording.reception[lang]}
                subTitle={txtWording.subtitleReception[lang]}
              />
            </WithAnimation>
          )}
          {isInvitation && (
            <WithAnimation>
              <Text
                fontSize="sm"
                textAlign="center"
                textTransform="uppercase"
                fontWeight="bold"
                color="secondaryColorText"
                dangerouslySetInnerHTML={{ __html: WEDDING_LOCATION }}
              />
              <Text
                fontSize="xs"
                textAlign="center"
                textTransform="uppercase"
                color="secondaryColorText"
              >
                {WEDDING_LOCATION_ROAD}
              </Text>
            </WithAnimation>
          )}
          {isInvitation && (
            <WithAnimation>
              <Center>
                <ViewLocationButton
                  margin="16px 2px 2px 2px"
                  size="xs"
                  fontWeight="light"
                  colorScheme="blackAlpha"
                  bgColor="bgPrimary"
                  color="white"
                  textTransform="uppercase"
                  letterSpacing="2px"
                  text={txtWording.maps[lang]}
                />
                {ENABLE_COVID_PROTOCOL && (
                  <Button
                    margin="16px 2px 2px 2px"
                    size="xs"
                    fontWeight="light"
                    colorScheme="blackAlpha"
                    bgColor="bgPrimary"
                    color="white"
                    textTransform="uppercase"
                    letterSpacing="2px"
                    onClick={() => onOpen(true)}
                    name={ID_COVID_SECTION}
                  >
                    {txtWording.protocol[lang]}
                  </Button>
                )}
              </Center>
            </WithAnimation>
          )}
          {/* QR Section - Only load if invitation mode and QR Enabled */}
          {ENABLE_QR_INVITATION && isInvitation && guest.status !== RSVP_STATUS.notAttend && (
            <QRCard marginTop="24px" name={ID_QRCODE} />
          )}
        </Box>
      </Box>
      <WeddingProtocol onClose={onClose} visible={isOpen} lang={lang} />
    </>
  );
}

export default React.memo(WeddingSection);
