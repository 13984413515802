import get from '@/helpers/getLocale';

export default {
  akad: get('Akad Nikah', 'Akad Nikah'),
  holy: get('Pemberkatan', 'Holy Matrimony'),
  reception: get('Resepsi Pernikahan', 'Wedding Reception'),
  location: get('Lokasi Acara', 'Wedding Location'),
  protocol: get('Protokol Kesehatan', 'Health Protocol'),
  maps: get('Lihat Peta', 'See Location'),
  subtitleAkad: get(
    'Dikarenakan kondisi saat ini, maka Akad Nikah akan dilaksanakan secara terbatas pada:',
    'Due to current conditions, Akad Nikah will be held with a limited capacity on:',
  ),
  subtitleHoly: get(
    'Dikarenakan kondisi saat ini, maka Pemberkatan akan dilaksanakan secara terbatas pada:',
    'Holy Matrimony will be held on:',
  ),
  subtitleReception: get(
    'Kami memohon kehadiran Bapak/Ibu/Saudara/i dalam pelaksanaan Resepsi Pernikahan pada:',
    'We request the presence of Mr/Mrs/Ms in the Wedding Reception on:',
  ),
};
